body{
  font-family: 'Inter', sans-serif;
  --control-border-color: #101113;
  --control-background-color: #fff;
  --control-caret-color: #000;
  --control-transition-duration: 0.3s;
  --control-transition-easing: ease;
  --control-dropdown-background-color: #eff2f5;
  --theme-color: #3861fb;
  --theme-light-color: #cfd6e4;
  --theme-light-blue-color: #e6f0ff;
  --theme-light-blue: #f0f6ff;
  --theme-light-blue-font: #3861fb;
  --text-color: #000;
  --info-icon-color: #a6b0c3;
  --text-color-sub: #58667e;
  --text-color-error: #ea3943;
  --bt-light-blue: #f0f6ff;
  --var-text-small: 14px;
  --up-color: #16c784;
  --down-color: #ea3943;
  --border-color: #eff2f5;
  --bg-color: #fff;
  --color-primary-black: #000;
  --color-primary-white: #fff;
  --color-primary-blue: #3861fb;
  --color-light-neutral-1: #f8fafd;
  --color-light-neutral-2: #eff2f5;
  --color-light-neutral-4: #a6b0c3;
  --color-light-neutral-5: #808a9d;
  --color-light-neutral-6: #58667e;
  --color-light-neutral-7: #eff2f5;
  --color-gradient-end: rgba(248,250,253,0);
  --button-border-color: #cfd6e4;
  --button-bg-color: #222531;
  --breakpoint-md: 768px;
  --dark-bg: #000;
  --card-bg: #fff;
  --box-shadow: 0px 0px 2px rgba(128,138,157,0.12),0px 0px 24px rgba(128,138,157,0.14);
  --table-background-color: #fff;
  --table-divider-color: #eff2f5;
  --table-row-hover-background-color: rgb(248,250,253);
}
a{
  text-decoration: none;
}
a:hover{
  color: #ffc828;
}
ul{
  list-style: none;
  padding: 0;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.App-header{
  background-color: #1b2a49;
  color: #fff; 
}
.logo img{
  max-width: 150px;
  margin-top: 10px;
}
.menu-main ul li a{
  font-size: 16px;
  color: #fff;
}
.menu-main ul li a:hover{
  color: #ffc828;
}
.footer{
  background-color: #1b2a49; 
  color: #fff;
}
.widget_footer ul li a{
  color: #ffffffb3;
  font-size: 16px;
  text-transform: capitalize;
  line-height: 26px;
}
.copy-right{
  font-size: 14px;
  font-weight: 500;
  padding: 20px;
}
.banner-home-page{
  background-image: url('./assets/images/banner_home2.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}
.banner-home-page h2{
    font-size: 40px;
    letter-spacing: 1px;
    font-weight: 900;
}
.banner-home-page .content{
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 1px;
}
.Cryptocurrency h3{
  font-size: 24px;
  line-height: 34px;
}
.list_cryptocurrency {
  box-shadow: rgb(88 102 126 / 8%) 0px 4px 24px, rgb(88 102 126 / 12%) 0px 1px 2px;
  border-radius: 8px;
}
.list_cryptocurrency .title{
  font-weight: bold;
  font-size: 16px;
  color: var(--color-primary-black); 
}
.list_cryptocurrency .item-list img{
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  top: 8px;
  margin-right: 10px ;
}
.item-list .item-title{
  font-weight: bold;
  font-size: 12px;
}
.item-list .item-title span{
  color: var(--color-light-neutral-5);
  font-weight: 500;
  margin: 0 5px;
}
.item-list .item-title .number{
  margin-right: 10px;
}
.item-list .item-price{
  position: relative;
  font-size: 12px;
  color: var(--up-color);
  top: 8px;
  font-weight: 600;
}
.item-list .item-price[data-change="down"]{
  color: var(--down-color);
}
.list-coins-table{
  margin: auto;
    font-size: 14px;
    width: 100%;
}
.list-coins-table thead th{
    line-height: 1.5;
    margin: 0px;
    color: rgb(23, 25, 36);
    font-size: 12px;
    border-top: 1px solid var(--table-divider-color);
    padding: 10px;
    text-transform: uppercase;
    font-weight: bold;
}
.list-coins-table thead th,
.list-coins-table tbody td{
  border-bottom: 1px solid var(--table-divider-color);
  background-color: var(--table-background-color);
  padding: 10px;
}
.list-coins-table tbody td .item-title img{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  top: 8px;
  margin-right: 10px;
}
.list-coins-table .item-price{
  font-size: 14px;
  font-weight: bold;
}
[data-change24h="up"] .item-price-graph img{
  filter: hue-rotate(85deg) saturate(80%) brightness(0.85);
}
[data-change24h="down"] .item-price-graph img{
  filter: hue-rotate(300deg) saturate(210%) brightness(0.7) contrast(170%);
}
.item-change{
  font-size: 14px;
  color: var(--up-color);
  font-weight: 600;
}
[data-change24h="down"] .item-change{
  color: var(--down-color);
}
.list-coins-table thead th:nth-child(1),
.list-coins-table tbody td:nth-child(1){
  position: sticky;
  left: 0px;
  z-index: 9;
}
.list-coins-table thead th:nth-child(2),
.list-coins-table tbody td:nth-child(2){
  position: sticky;
  left: 40px;
  z-index: 9;
}
@media only screen and (max-width: 768px) {
.list-coins-table tbody tr td:nth-child(2) .item-title{
    display: flex;
  }
.list-coins-table tbody td:nth-child(2) .item-title .coin_name{
    display: inline-block;
    margin-top: 10px;
  }
  .list-coins-table tbody td:nth-child(2) .item-title .coin_name .symbol{
    display: block;
    color: #808a9d;
  }
  .menu-main.mobile{
    position: fixed;
    display: inline-block;
    width: 80%;
    height: 100vh;
    background-color: #fff;
    z-index: 9;
    padding-top: 60px;
    top: 0;
    margin: 0;
    right: 0;
  }
  body .menu-main.mobile li{
    display: block;
    text-align: center;
  }
  body .menu-main.mobile li a{
   color: #352d2d;
   font-size: 22px;
   margin-bottom: 20px;
   display: block;
  }
  .App-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    height: 52px;
  }
  .banner-home-page{
    padding-top: 60px;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
