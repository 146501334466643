/* Reset */
h1,h2,h3,h4,h5{
  margin: 0;
}
p{
  margin: 0;
  padding: 0;
}
img{
  max-width: 100%;
}
.rel {
    position: relative;
  }
.container-fullwidth{
  padding: 0 30px;
}  
.container{
  max-width: 1402px;
  margin: 0 auto;
}
  .d-grid {
    display: flex;
    flex-wrap: wrap;
  }
  .d-grid-flex {
    display: flex;
  }
  .align-center {
    align-items: center;
  }
  .d-inline {
    display: inline-block;
  }
  .d-block {
    display: block !important;
  }
  .d-grid-inline > * {
    display: inline-block;
  }
  .col-10 {
    width: 100%;
  }
  .col-8 {
    width: 80%;
  }
  .col-7 {
    width: 70%;
  }
  .col-6-5 {
    width: 65%;
  }
  .col-6 {
    width: 60%;
  }
  .col-5 {
    width: 50%;
  }
  .col-4-5 {
    width: 45%;
  }
  .col-4 {
    width: 40%;
  }
  .col-3-6 {
    width: 36%;
  }
  .col-3-5 {
    width: 35%;
  }
  .col-3 {
    width: 30%;
  }
  .col-2-5 {
    width: 25%;
  }
  .col-2 {
    width: 20%;
  }
  .col-1-5 {
    width: 15%;
  }
  .d-grid-1-3 > * {
    width: calc(100% / 3);
  }
  .d-float-right {
    float: right;
  }
  .d-text-align-center {
    text-align: center;
  }
  .d-text-align-right{
    text-align: right;
  }
  .d-text-align-left{
    text-align: left;
  }
  .d-margin-tb-5 {
    margin: 50px 0;
  }
  .pb-30 {
    padding-bottom: 30px;
  }
  .d-padding-t-2 {
    padding-top: 20px;
  }
  .d-padding-t-10 {
    padding-top: 100px;
  }
  .d-padding-tb-4 {
    padding: 40px 0;
  }
  .d-padding-tb-2 {
    padding: 20px 0;
  }
  .d-padding-tb-1 {
    padding: 10px 0;
  }
  .d-padding-8 {
    padding: 80px;
  }
  .d-padding-2 {
    padding: 20px;
  }
  .d-padding-1 {
    padding: 10px;
  }
  .d-padding-0 {
    padding: 0;
  }
  
  .d-padding-b-4 {
    padding-bottom: 40px;
  }
  .d-padding-l-2 {
    padding-left: 20px;
  }
  .d-padding-l-1 {
    padding-left: 10px;
  }
  .d-margin-0 {
    margin: 0;
  }
  .mr-20 {
    margin-right: 20px;
  }
  .d-margin-t-8 {
    margin-top: 80px;
  }
  .d-margin-t-4 {
    margin-top: 40px;
  }
  .d-margin-t-2 {
    margin-top: 20px;
  }
  .d-margin-t-1 {
    margin-top: 10px;
  }
  .d-margin-11 {
    margin: 110px 0;
  }
  .d-margin-tb-4 {
    margin: 40px 0;
  }
  .d-margin-tb-8 {
    margin: 80px 0;
  }
  .d-margin-tb-12 {
    margin: 120px 0;
  }
  .d-margin-tb-2 {
    margin: 20px 0;
  }
  .d-margin-2 {
    margin: 20px;
  }
  .d-margin-1 {
    margin: 10px;
  }
  .d-grid-4 > * {
    width: 25%;
    display: inline-block;
  }
  .m-2 {
    margin: 20px;
  }
  .relative {
    position: relative;
  }
  .w-full {
    width: 100%;
  }
  .gap-100 {
    grid-gap: 100px;
  }
  .clear-both::after {
    content: "";
    clear: both;
    display: block;
  }
  .just-align-center {
    margin: 0 auto;
    float: inherit;
    display: block;
  }
  .field-control {
    width: 100%;
    border-radius: 4px;
    border: solid 1px #b7bbc7;
    padding: 10px;
  }
  .field-control > * {
    border: none;
    width: 100%;
    outline: none;
  }
  .error {
    font-size: 14px;
    color: #f66355;
  }
  .btn-primary{
    padding: 10px 40px;
    border-radius: 24px;
    background-color: #2567e5;
    color: #fff;
    border-color: transparent;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 10px;
    cursor: pointer;
    transition: ease-in-out 0.3;
  }
  .btn-info{
    padding: 10px 40px;
    border-radius: 24px;
    background-color: transparent;
    color: #fff;
    border-color: transparent;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 10px;
    cursor: pointer;
    transition: ease-in-out 0.3;
  }
  .btn-info:hover,
  .btn-primary:hover{
    background-color: #ffc828;
    color: #000;
  }
  .text-justify{
    text-align: justify;
  }
  .hamburger {
    float: right;
    display: none;
    position: relative;
  }
  #nav-menu-mobile{
    width: 24px;
    height: 32px;
    position: relative;
    top: 15px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer; 
    z-index: 9;
    margin-left: 10px;
  }
  #nav-menu-mobile span{
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  #nav-menu-mobile.open span{
    background: #190e3d; 
  }
  #nav-menu-mobile span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  
  #nav-menu-mobile span:nth-child(2) {
    top: 10px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  
  #nav-menu-mobile span:nth-child(3) {
    top: 20px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  
  #nav-menu-mobile.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -3px;
    left: 8px;
  }
  
  #nav-menu-mobile.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }
  
  #nav-menu-mobile.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 14px;
    left: 8px;
  }
  .d-none{
    display: none !important;
  }
  .d-scroll{
    overflow: scroll;
  }
  @media only screen and (max-width: 768px) {
    .col-4{
      width: 100%;
    }
    .d-grid-1-3 > *{
      width: 100%;
    }
    .d-padding-8 {
      padding: 10px;
    }
    .hamburger{
      display: inline-block;
    }
    .hamburger.open{
      position: fixed;
      top: 10px;
      right: 20px;
      z-index: 10;
    }
    .show-sm {
      display: block;
    }
    .hide-sm {
      display: none;
    }
    .btn-primary{
      padding: 5px 20px;
      text-transform: capitalize;
    }
  }